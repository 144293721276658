import { useTranslation } from 'react-i18next';
import React, { Fragment, useCallback, useEffect } from 'react';

import { PaymentMethodInput } from '../../__generated__/globalTypes';
import UI from '../UI';
import usePaymentMethods from '../usePaymentMethods';

interface PaymentMethodSelectionProps {
  value: Partial<PaymentMethodInput>;
  onChange: (value: Partial<PaymentMethodInput>) => void;
  onBlur?: (key: keyof Partial<PaymentMethodInput>) => void;
  paymentMethods: string[];
}

const PaymentMethodSelection = ({ value, onChange, onBlur, paymentMethods }: PaymentMethodSelectionProps) => {
  const { t } = useTranslation('common');
  const { getPaymentMethodImageUrl, getPaymentMethodTitle } = usePaymentMethods();

  const setPaymentMethod = useCallback(
    (paymentMethod: string | null) => {
      onChange({
        ...value,
        payment_method: paymentMethod,
        issuer: null,
      });
    },
    [onChange, value],
  );

  const selectedPaymentMethod = value.payment_method;

  // If there is exactly 1 payment method, select it.
  useEffect(() => {
    if (paymentMethods.length === 1 && value.payment_method !== paymentMethods[0]) {
      setPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods, value.payment_method, setPaymentMethod]);

  return (
    <>
      {paymentMethods.length > 0 && (
        <UI.FormGrid sc={{ gutter: 0.25 }}>
          {paymentMethods.map((paymentMethod) => (
            <UI.Radio
              onChange={() => setPaymentMethod(paymentMethod)}
              onBlur={() => onBlur?.('payment_method')}
              checked={paymentMethod === selectedPaymentMethod}
              sc={{ box: true, radioTop: 13 }}
              style={{ flex: 1 }}
              key={paymentMethod}
            >
              <UI.FlexContainer sc={{ alignItems: 'center' }}>
                <UI.Span sc={{ mr: 2 }}>
                  <img
                    src={getPaymentMethodImageUrl(paymentMethod)}
                    alt={getPaymentMethodTitle(paymentMethod)}
                    style={{ width: 32, height: 24, display: 'block' }}
                  />
                </UI.Span>
                <UI.Span sc={{ fontWeight: 500 }}>
                  {getPaymentMethodTitle(paymentMethod)}
                </UI.Span>
              </UI.FlexContainer>
            </UI.Radio>
          ))}
        </UI.FormGrid>
      )}
      {paymentMethods.length === 0 && (
        <UI.Warning>
          {t('payments.no_payment_method_available')}
        </UI.Warning>
      )}
    </>
  );
};

export default PaymentMethodSelection;
