import * as yup from 'yup';
import { ArrowUpRight, Lock } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-form-state-manager';
import React, { useContext, useEffect, useState } from 'react';

import { AddOrderStatus, PaymentMethodInput, ResellRegistrationInput } from '../../__generated__/globalTypes';
import { EmbedContext } from '../Common/EmbedProvider';
import {
  GetResellableRegistration_event as Event, GetResellableRegistration_resellableRegistration as Registration,
} from './__generated__/GetResellableRegistration';
import { ResellRegistration, ResellRegistrationVariables } from './__generated__/ResellRegistration';
import { containsKeyPrefix, emailRegex, getServerErrors } from '../../common/helpers';
import { getParticipantAttributesSchema } from '../../common/ParticipantAttributes/helpers';
import { getParticipantFieldEntrySchema, prefillFieldEntries } from '../../common/ParticipantFields/helpers';
import EditRegistrationForm, { getFundraisingSettings } from '../../common/Registrations/EditRegistrationForm';
import PaymentMethodSelection from '../../common/Payments/PaymentMethodSelection';
import PlatformFeeLine from '../../common/Purchases/PlatformFeeLine';
import ResellRegistrationMutation from './ResellRegistrationMutation';
import UI from '../../common/UI';
import config from '../config';
import useLocale from '../../common/useLocale';
import useMutation from '../../api/useMutation';
import usePaymentMethods from '../../common/usePaymentMethods';
import useValidation from '../../common/useValidation';

const getValues = (
  registration: Registration,
  resaleToken: string,
  defaultCountry?: string,
): ResellRegistrationInput => ({
  id: registration.id,
  participant: {},
  details: {
    country: defaultCountry,
    nationality: defaultCountry,
  },
  fields: prefillFieldEntries([], registration.participant_fields),
  upgrades: registration.upgrades.filter(
    (upgrade) => upgrade.product.active_product_variants.length > 0 || upgrade.participant_fields.length > 0,
  ).map((upgrade) => ({
    id: upgrade.id,
    product_variant: (upgrade.product_variant || upgrade.product.active_product_variants.length > 0) ? {
      id: upgrade.product_variant?.id,
    } : null,
    fields: prefillFieldEntries([], upgrade.participant_fields),
  })),
  payment_method: {
    payment_method: null,
  },
  resale_token: resaleToken,
});

const getResellRegistrationSchema = (
  registration: Registration,
  event: Event,
  allowedPaymentMethods: string[],
) => {
  const fields = [
    ...registration.participant_fields,
    ...registration.upgrades.reduce((fields, upgrade) => [
      ...fields, ...upgrade.participant_fields,
    ], []),
  ];

  const fieldEntrySchema = getParticipantFieldEntrySchema(fields);

  const { requireCharity } = getFundraisingSettings(registration, event);

  return yup.object({
    participant: yup.object({
      email: yup.string().required().matches(emailRegex, { name: 'email' }),
      first_name: yup.string().ensure().required(),
      last_name: yup.string().ensure().required(),
    }),
    details: getParticipantAttributesSchema(registration.required_attributes),
    fields: yup.array(fieldEntrySchema),
    upgrades: yup.array(
      yup.object({
        product_variant: yup.object({
          id: yup.string().ensure().required(),
        }).nullable().default(null),
        fields: yup.array(fieldEntrySchema),
      }),
    ),
    charity: requireCharity ? yup.object().required() : yup.object().nullable().default(null),
    payment_method: yup.object({
      payment_method: (yup.string().ensure().required()).test('invalid', (value) => (
        !value || allowedPaymentMethods.includes(value)
      )),
    }),
    terms: yup.boolean().test('required', '', (value) => (
      event.terms || event.terms_url || event.terms_text ? value : true
    )),
  });
};

export interface BuyRegistrationFormProps {
  event: Event;
  registration: Registration;
  resaleToken: string;
}

const BuyRegistrationForm = ({
  event,
  registration,
  resaleToken,
}: BuyRegistrationFormProps) => {
  const { t } = useTranslation();
  const { embedded, scrollTop } = useContext(EmbedContext);
  const { locale } = useLocale();

  const form = useForm<ResellRegistrationInput>({
    values: getValues(registration, resaleToken, event.project.organisation_country),
  });

  const editPaymentMethod = (paymentMethod: Partial<PaymentMethodInput>) => {
    form.set((form) => ({
      ...form,
      payment_method: {
        payment_method: paymentMethod.payment_method || null,
        issuer: paymentMethod.issuer,
      },
    }));
  };

  const { allowedPaymentMethods } = usePaymentMethods({
    country: form.values.details.country,
    enabledPaymentMethods: event.payment_methods,
    bankTransferAllowed: false,
  });

  // Used to disable the 'Pay' button while the browser is redirecting the user, to
  // prevent the user from creating another payment in the mean time.
  // Unfortunately we can't simply disable the button if resellRegistration data is present,
  // because this state object is restored by Safari on pressing the browser back button.
  // In that case, the user should be able to resubmit the form.
  const [redirecting, setRedirecting] = useState(false);

  const [
    resellRegistration, { data, error, loading: submitting },
  ] = useMutation<ResellRegistration, ResellRegistrationVariables>(
    ResellRegistrationMutation,
    {
      variables: {
        input: {
          ...form.values,
          payment_method: form.values.payment_method.payment_method
            ? form.values.payment_method as PaymentMethodInput : null,
        },
      },
      onCompleted: ({ resellRegistration: { status, redirect_url } }) => {
        if (status === AddOrderStatus.SUCCESS) {
          if (redirect_url) {
            // Disable the 'Pay' button while redirecting.
            setRedirecting(true);

            if (embedded) {
              // Use window.parent, because in Cypress window.top is the spec runner window.
              window.parent.location.href = redirect_url;
            } else {
              window.location.href = redirect_url;
            }
          }
        }
      },
      onError: () => {
        scrollTop();
      },
    },
  );

  // After 10 seconds, re-enable the 'Pay' button. The user is most likely long gone at this point,
  // but if they use the back button of their browser, they should be able to resubmit the form.
  useEffect(() => {
    if (redirecting) {
      const timer = window.setTimeout(() => {
        setRedirecting(false);
      }, 10000);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [redirecting]);

  const loading = submitting || redirecting;

  const orderStatus = data?.resellRegistration.status;

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const toggleTerms = () => {
    setAgreedToTerms((agreed) => !agreed);
    form.setTouched('terms', true);
  };

  const termsText = event.terms_text;
  const termsUrl = event.terms_url || event.terms?.url;
  const requireTerms = termsText || termsUrl;

  const { errors, valid, updateCustomErrors, updateValidating } = useValidation({
    schema: getResellRegistrationSchema(registration, event, allowedPaymentMethods),
    values: {
      ...form.values,
      terms: agreedToTerms,
    },
    externalErrors: getServerErrors(error),
  });

  const paymentMethodError = containsKeyPrefix(errors, 'payment_method');

  const scrollToErrorFields = () => {
    scrollTop();
    Object.keys(errors).forEach((key: string) => form.setTouched(key, true));
  };

  return (
    <UI.Form onSubmit={() => resellRegistration()} id="CheckoutForm">
      <UI.FormGrid sc={{ separatorV: true, gutter: 0 }}>
        {error && (
          <UI.Div sc={{ padding: [3, 4] }}>
            <UI.ServerErrors error={error} />
          </UI.Div>
        )}

        {orderStatus === AddOrderStatus.START_PAYMENT_FAILED && (
          <UI.Div sc={{ padding: [3, 4] }}>
            <UI.Error>
              <UI.Strong sc={{ block: true }}>
                {t('common:payments.could_not_start_payment')}
              </UI.Strong>
              {t('common:payments.try_again_in_a_minute')}
            </UI.Error>
          </UI.Div>
        )}

        <UI.Div sc={{ padding: [3, 4] }}>
          <EditRegistrationForm
            form={form}
            registration={{
              ...registration,
              ticket: {
                ...registration.ticket,
                // Hide time slot selection
                upcoming_time_slots: [],
                // Hide team selection
                allow_individuals: true,
                allow_create_team: false,
                allow_join_team: false,
              },
              editable_attributes: [],
            }}
            event={event}
            errors={errors}
            updateCustomErrors={updateCustomErrors}
            updateValidating={updateValidating}
          />
        </UI.Div>

        <UI.FormGrid sc={{ padding: [3, 4] }}>
          <UI.Legend>
            {t('payment')}
          </UI.Legend>

          <UI.InputGroup>
            <UI.InputLabel>
              {t('common:payments.payment_method')}
              {' '}
              <UI.RequiredMark sc={{ valid: !paymentMethodError }} />
            </UI.InputLabel>
            <PaymentMethodSelection
              value={form.values.payment_method}
              onChange={(paymentMethod) => editPaymentMethod(paymentMethod)}
              paymentMethods={allowedPaymentMethods}
            />
          </UI.InputGroup>

          <UI.HR sc={{ dashed: true }} />

          <UI.Legend>
            {t('your_order')}
          </UI.Legend>

          <OrderSummary registration={registration} />

          {requireTerms && (
            <>
              <UI.HR sc={{ dashed: true }} />
              <UI.InputGroup sc={{ valid: !errors.terms, touched: form.touched.terms }}>
                <UI.InputLabel>
                  {t('terms_and_conditions')}
                  {' '}
                  <UI.RequiredMark />
                </UI.InputLabel>
                {termsUrl && (
                  <UI.Checkbox
                    checked={agreedToTerms}
                    onChange={() => toggleTerms()}
                  >
                    <Trans i18nKey="agree_to_terms">
                      {/* eslint-disable */}
                      <a href={termsUrl} target="_blank" rel="noopener noreferrer" />
                      {/* eslint-enable */}
                    </Trans>
                  </UI.Checkbox>
                )}
                {termsText && (
                  <UI.Checkbox
                    checked={agreedToTerms}
                    onChange={() => toggleTerms()}
                  >
                    <UI.HTML html={termsText} />
                  </UI.Checkbox>
                )}
                <UI.ErrorMessages attribute={t('terms_and_conditions')} errors={errors.terms} />
              </UI.InputGroup>
            </>
          )}

          {!valid && (
            <UI.FadeIn>
              <UI.Warning>
                <UI.A
                  onClick={() => scrollToErrorFields()}
                  sc={{ secondary: true }}
                  style={{ fontWeight: 500 }}
                  role="button"
                >
                  {t('fill_required_fields')}
                  {' '}
                  <UI.Icon>
                    <ArrowUpRight />
                  </UI.Icon>
                </UI.A>
              </UI.Warning>
            </UI.FadeIn>
          )}
        </UI.FormGrid>

        <UI.FormGrid sc={{ padding: [3, 4] }}>
          <UI.Div>
            <Trans
              i18nKey="common:terms.terms_notice"
              values={{
                action: t('pay'),
              }}
            >
              <UI.A href={config.termsUrl[locale]} target="_blank" rel="noopener noreferer" />
            </Trans>
          </UI.Div>
          <UI.Button
            type="submit"
            sc={{ block: true, brand: 'secondary', loading }}
            disabled={!valid || loading}
          >
            {loading
              ? <UI.Loader sc={{ brand: 'white' }} />
              : (
                <>
                  <UI.Icon>
                    <Lock />
                  </UI.Icon>
                  {' '}
                  {t('pay')}
                </>
              )}
          </UI.Button>
        </UI.FormGrid>
      </UI.FormGrid>
    </UI.Form>
  );
};

interface OrderSummaryProps {
  registration: Registration;
}

export const OrderSummary = ({
  registration,
}: OrderSummaryProps) => {
  const { t } = useTranslation();
  const { formatCurrency } = useLocale();

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }}>
      <UI.GridContainer sc={{ gutter: 0.5 }}>
        <UI.GridContainer sc={{ columns: '14px 1fr fit-content(100px)', gutter: 0.5 }}>
          <UI.Div>
            <UI.Icon>
              <UI.Icons.Ticket />
            </UI.Icon>
          </UI.Div>
          <UI.Div sc={{ strong: true }}>
            <UI.Delimit>
              {registration.ticket.title}
              {registration.promotion.title}
            </UI.Delimit>
          </UI.Div>
          <UI.Div sc={{ textAlign: 'right', noWrap: true }}>
            {formatCurrency(registration.resale.amount)}
          </UI.Div>
        </UI.GridContainer>
        {registration.upgrades.map((upgrade) => (
          <UI.GridContainer sc={{ columns: '14px 1fr fit-content(100px)', gutter: 0.5 }} key={upgrade.id}>
            <UI.Div />
            <UI.Div>
              {upgrade.product.title}
            </UI.Div>
            <UI.Div sc={{ textAlign: 'right', noWrap: true }}>
              {formatCurrency(upgrade.resale_amount)}
            </UI.Div>
          </UI.GridContainer>
        ))}
      </UI.GridContainer>
      <UI.HR />
      <PlatformFeeLine fee={registration.resale.fee} />
      <UI.HR sc={{ borderWidth: 2 }} />
      <UI.GridContainer sc={{ columns: '1fr fit-content(100px)', gutter: 0.5 }}>
        <UI.Div sc={{ fontSize: 3, fontWeight: 500 }}>
          {t('total')}
        </UI.Div>
        <UI.Div sc={{ fontSize: 3, strong: true, textAlign: 'right', noWrap: true }}>
          {formatCurrency(registration.resale.total_amount + registration.resale.fee)}
        </UI.Div>
      </UI.GridContainer>
    </UI.GridContainer>
  );
};

export default BuyRegistrationForm;
