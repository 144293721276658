/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddOrderStatus {
  START_PAYMENT_FAILED = "START_PAYMENT_FAILED",
  SUCCESS = "SUCCESS",
}

export enum AssignmentType {
  afterward = "afterward",
  allowed = "allowed",
  required = "required",
}

export enum CouponStatus {
  active = "active",
  inactive = "inactive",
  invalid = "invalid",
  used_up = "used_up",
}

export enum CouponType {
  absolute = "absolute",
  percentage = "percentage",
}

export enum DesignQuoteType {
  CLOTHING = "CLOTHING",
  CORPORATE_IDENTITY = "CORPORATE_IDENTITY",
  FLYERS_POSTERS = "FLYERS_POSTERS",
  MEDALS = "MEDALS",
  OTHER = "OTHER",
}

export enum DiscountType {
  absolute = "absolute",
  percentage = "percentage",
}

export enum EventCategory {
  athletics = "athletics",
  climbing = "climbing",
  combat_sport = "combat_sport",
  cycling = "cycling",
  fishing = "fishing",
  fitness = "fitness",
  football = "football",
  golf = "golf",
  gym = "gym",
  handball = "handball",
  horse_riding = "horse_riding",
  ice_skating = "ice_skating",
  obstacle_run = "obstacle_run",
  rowing = "rowing",
  running = "running",
  sailing = "sailing",
  skiing = "skiing",
  surfing = "surfing",
  swimming = "swimming",
  tennis = "tennis",
  triathlon = "triathlon",
  volleyball = "volleyball",
  walking = "walking",
  yoga = "yoga",
}

export enum ExportFormat {
  csv = "csv",
  excel = "excel",
  mailchimp = "mailchimp",
}

export enum FundraisingMode {
  after_registration = "after_registration",
  during_registration = "during_registration",
}

export enum Gender {
  female = "female",
  male = "male",
  neutral = "neutral",
}

export enum Locale {
  de = "de",
  en = "en",
  fr = "fr",
  nl = "nl",
}

export enum ParticipantAttributes {
  address = "address",
  date_of_birth = "date_of_birth",
  emergency_phone = "emergency_phone",
  gender = "gender",
  nationality = "nationality",
  phone = "phone",
}

export enum ParticipantFieldImportErrorType {
  duplicate_id = "duplicate_id",
  invalid_id = "invalid_id",
  invalid_value = "invalid_value",
}

export enum ParticipantFieldScope {
  order = "order",
  purchase = "purchase",
}

export enum ParticipantFieldType {
  address = "address",
  au_license = "au_license",
  iban = "iban",
  integer = "integer",
  knwu_license = "knwu_license",
  kwbn_member = "kwbn_member",
  ntfu_member = "ntfu_member",
  option = "option",
  selection = "selection",
  short_text = "short_text",
  time = "time",
}

export enum PspType {
  mollie = "mollie",
  test = "test",
}

export enum PurchaseFilterType {
  products = "products",
  tickets = "tickets",
}

export enum PurchasePaymentStatus {
  has_balance = "has_balance",
  open = "open",
  paid = "paid",
  refunded = "refunded",
}

export enum PurchaseStatus {
  complete = "complete",
  inactive = "inactive",
  incomplete = "incomplete",
}

export enum RegistrationProblems {
  ticket_incomplete = "ticket_incomplete",
  ticket_unassigned = "ticket_unassigned",
}

export enum TicketAttribute {
  address = "address",
  date_of_birth = "date_of_birth",
  email = "email",
  emergency_phone = "emergency_phone",
  gender = "gender",
  name = "name",
  nationality = "nationality",
  phone = "phone",
}

export enum UserRole {
  admin = "admin",
  dashboard = "dashboard",
  manager = "manager",
}

export interface AddCouponInput {
  event: ModelType;
  tickets?: ModelType[] | null;
  products?: ModelType[] | null;
  prefix: string;
  type: string;
  value: number;
  start_date?: string | null;
  end_date?: string | null;
  max_uses?: number | null;
  active?: boolean | null;
}

export interface AddDiscountInput {
  event: ModelType;
  tickets?: ModelType[] | null;
  products?: ModelType[] | null;
  title: string;
  title_translations?: TranslationInput[] | null;
  type: DiscountType;
  start_date?: string | null;
  end_date?: string | null;
  levels: DiscountLevelInput[];
  active?: boolean | null;
}

export interface AddEventInput {
  project: ModelType;
  participant_attributes?: ParticipantAttributes[] | null;
  info: SaveEventInfoInput;
  locale?: SaveEventLocaleInput | null;
  restrictions?: SaveEventRestrictionsInput | null;
  confirmation_messages?: SaveEventConfirmationInput | null;
  dashboard?: SaveEventDashboardSettingsInput | null;
  terms?: SaveEventTermsInput | null;
  branding?: SaveEventBrandingInput | null;
  tickets?: SaveEventTicketSettingsInput | null;
  payments?: SaveEventPaymentSettingsInput | null;
  resale?: SaveEventResaleSettingsInput | null;
  fundraising?: SaveEventFundraisingSettingsInput | null;
}

export interface AddInvitationInput {
  event: ModelType;
  prefix: string;
  unique_codes?: boolean | null;
  max_uses?: number | null;
  ignore_capacity?: boolean | null;
  tickets?: ModelType[] | null;
  promotions?: ModelType[] | null;
  time_slots?: ModelType[] | null;
  start_date?: string | null;
  end_date?: string | null;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  confirmation_mail_text?: string | null;
  confirmation_mail_text_translations?: TranslationInput[] | null;
  team?: FindOrAddTeamInput | null;
  active?: boolean | null;
}

export interface AddMailPermissionInput {
  project?: ModelType | null;
  title: string;
  title_translations?: TranslationInput[] | null;
  label?: string | null;
  label_translations?: TranslationInput[] | null;
  implicit: boolean;
  events?: string[] | null;
}

export interface AddMessageInput {
  event: ModelType;
  title: string;
  subject?: string | null;
  content?: string | null;
  published: boolean;
  publication_date?: string | null;
  filters?: AddSegmentInput | null;
  mail_permissions?: string[] | null;
}

export interface AddNoteInput {
  participant: ModelType;
  content: string;
}

export interface AddOrderInput {
  source_url?: string | null;
  event: ModelType;
  coupon?: string | null;
  invitation_code?: string | null;
  participant: ModelType;
  registrations: RegistrationsInput;
  team?: TeamInput | null;
  invoice?: InvoiceDetailsInput | null;
  down_payment?: boolean | null;
  due_date?: string | null;
  session_id?: string | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface AddParticipantFieldImportInput {
  event: ModelType;
  participant_field?: ModelType | null;
  data: any;
}

export interface AddParticipantFieldInput {
  event: ModelType;
  title: string;
  title_translations?: TranslationInput[] | null;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  type: string;
  scope: string;
  required: boolean;
  unique?: boolean | null;
  config?: string | null;
  validation_url?: string | null;
  validation_success_response?: string | null;
  choices?: SaveParticipantFieldChoiceInput[] | null;
  tickets?: ModelType[] | null;
  products?: ModelType[] | null;
  promotions?: ModelType[] | null;
  enabled?: boolean | null;
}

export interface AddParticipantInput {
  id?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mail_opt_ins?: string[] | null;
  charity?: ModelType | null;
  unset_charity?: boolean | null;
  locale?: Locale | null;
  project: ModelType;
}

export interface AddProductInput {
  event: ModelType;
  donation?: boolean | null;
  info: SaveProductInfoInput;
  pricing: SaveProductPricingInput;
  restrictions?: SaveProductRestrictionsInput | null;
  product_variants?: SaveProductVariantInput[] | null;
  active?: boolean | null;
}

export interface AddProjectInput {
  organisation_name: string;
  locale: Locale;
  timezone: string;
  currency?: string | null;
  promo?: string | null;
  tracking_data?: string | null;
}

export interface AddReviewInput {
  order: ModelType;
  rating: number;
  comment?: string | null;
  token: string;
}

export interface AddScannerInput {
  event: ModelType;
  info: SaveScannerInfoInput;
  filters?: AddSegmentInput | null;
}

export interface AddSegmentInput {
  search?: string | null;
  events?: string[] | null;
  tickets?: string[] | null;
  time_slots?: string[] | null;
  ticket_number_sequences?: string[] | null;
  has_ticket_number?: boolean | null;
  has_team?: boolean | null;
  products?: string[] | null;
  product_variants?: string[] | null;
  promotions?: string[] | null;
  participant_fields?: ParticipantFieldFilter[] | null;
  participant_field_choices?: string[] | null;
  invitations?: string[] | null;
  coupons?: string[] | null;
  discounts?: string[] | null;
  mail_opt_ins?: string[] | null;
  received_messages?: string[] | null;
  will_receive_messages?: string[] | null;
  statuses?: PurchaseStatus[] | null;
  payment_statuses?: PurchasePaymentStatus[] | null;
  active?: boolean | null;
  genders?: Gender[] | null;
  min_age?: number | null;
  max_age?: number | null;
}

export interface AddTeamInput {
  event: ModelType;
  title: string;
  captain: ParticipantInput;
}

export interface AddTicketCategoryInput {
  event: ModelType;
  title: string;
  title_translations?: TranslationInput[] | null;
  capacity?: number | null;
}

export interface AddTicketInput {
  event: ModelType;
  ticket_category?: ModelType | null;
  info: SaveTicketInfoInput;
  pricing: SaveTicketPricingInput;
  type?: SaveTicketTypeInput | null;
  teams?: SaveTicketTeamsInput | null;
  restrictions?: SaveTicketRestrictionsInput | null;
  time_slots?: SaveTimeSlotInput[] | null;
}

export interface AddTicketNumberSequenceInput {
  event: ModelType;
  info: SaveTicketNumberSequenceInfoInput;
  filters?: AddSegmentInput | null;
}

export interface AddUserInput {
  email: string;
  locale: Locale;
  first_name?: string | null;
  last_name?: string | null;
  promo?: string | null;
}

export interface AddUserToProjectInput {
  project_id: string;
  email: string;
  role: string;
}

export interface AnalyticsFilters {
  event: string;
  tickets?: string[] | null;
}

export interface AssignTicketNumbersInput {
  event: ModelType;
  ticket_number_sequence?: ModelType | null;
}

export interface BusinessRegistrationInput {
  promotion: ModelType;
  time_slot?: ModelType | null;
  quantity: number;
  upgrades?: CreateUpgradeInput[] | null;
  charity?: ModelType | null;
}

export interface CheckoutSummaryInput {
  event: ModelType;
  registrations: RegistrationsInput;
  invitation_code?: string | null;
  coupon?: string | null;
  coupon_validation?: boolean | null;
  vat_id?: string | null;
  queue_token?: string | null;
}

export interface CompleteCheckoutInput {
  locale?: Locale | null;
  source_url?: string | null;
  event: ModelType;
  registrations: RegistrationsInput;
  invitation_code?: string | null;
  coupon?: string | null;
  participant: ParticipantInput;
  team?: TeamInput | null;
  invoice?: InvoiceDetailsInput | null;
  payment_method?: PaymentMethodInput | null;
  down_payment?: boolean | null;
  charity?: ModelType | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface CreateRegistrationInput {
  ticket: ModelType;
  time_slot?: ModelType | null;
  purchase: SavePurchaseInput;
  upgrades?: CreateUpgradeInput[] | null;
  team?: TeamInput | null;
  participant?: ParticipantInput | null;
  details?: RegistrationDetailsInput | null;
  charity?: ModelType | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface CreateUpgradeInput {
  product: ModelType;
  product_variant?: ModelType | null;
  purchase: SavePurchaseInput;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface DeactivateParticipantInput {
  id: string;
}

export interface DiscountLevelInput {
  quantity: number;
  value: number;
}

export interface DuplicateEventInput {
  event: ModelType;
  title?: string | null;
  start_date?: string | null;
}

export interface EditCouponInput {
  tickets?: ModelType[] | null;
  products?: ModelType[] | null;
  prefix: string;
  type: string;
  value: number;
  start_date?: string | null;
  end_date?: string | null;
  max_uses?: number | null;
  active?: boolean | null;
  id: string;
}

export interface EditDiscountInput {
  tickets?: ModelType[] | null;
  products?: ModelType[] | null;
  title: string;
  title_translations?: TranslationInput[] | null;
  type: DiscountType;
  start_date?: string | null;
  end_date?: string | null;
  levels: DiscountLevelInput[];
  active?: boolean | null;
  id: string;
}

export interface EditEventSettingsInput {
  participant_attributes?: ParticipantAttributes[] | null;
  info?: SaveEventInfoInput | null;
  locale?: SaveEventLocaleInput | null;
  restrictions?: SaveEventRestrictionsInput | null;
  confirmation_messages?: SaveEventConfirmationInput | null;
  dashboard?: SaveEventDashboardSettingsInput | null;
  terms?: SaveEventTermsInput | null;
  branding?: SaveEventBrandingInput | null;
  tickets?: SaveEventTicketSettingsInput | null;
  payments?: SaveEventPaymentSettingsInput | null;
  resale?: SaveEventResaleSettingsInput | null;
  fundraising?: SaveEventFundraisingSettingsInput | null;
  id: string;
}

export interface EditFeaturesInput {
  project: ModelType;
  gender_neutral?: boolean | null;
}

export interface EditIntegrationsInput {
  project: ModelType;
  google_analytics?: string | null;
  facebook_pixel?: string | null;
}

export interface EditInvitationInput {
  prefix: string;
  unique_codes?: boolean | null;
  max_uses?: number | null;
  ignore_capacity?: boolean | null;
  tickets?: ModelType[] | null;
  promotions?: ModelType[] | null;
  time_slots?: ModelType[] | null;
  start_date?: string | null;
  end_date?: string | null;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  confirmation_mail_text?: string | null;
  confirmation_mail_text_translations?: TranslationInput[] | null;
  team?: FindOrAddTeamInput | null;
  active?: boolean | null;
  id: string;
}

export interface EditLocaleInput {
  project: ModelType;
  locale: Locale;
  timezone: string;
}

export interface EditMailOptInsInput {
  id: string;
  mail_opt_ins: string[];
}

export interface EditMailPermissionInput {
  id: string;
  title: string;
  title_translations?: TranslationInput[] | null;
  label?: string | null;
  label_translations?: TranslationInput[] | null;
  events?: string[] | null;
}

export interface EditMessageInput {
  title?: string | null;
  subject?: string | null;
  content?: string | null;
  published?: boolean | null;
  publication_date?: string | null;
  filters?: AddSegmentInput | null;
  mail_permissions?: string[] | null;
  id: string;
}

export interface EditNoteInput {
  id: string;
  content: string;
}

export interface EditOrderInput {
  id: string;
  invoice?: InvoiceDetailsInput | null;
  unset_invoice_details?: boolean | null;
  stand_alone_upgrades?: EditUpgradeInput[] | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface EditOrganisationInput {
  project: ModelType;
  organisation_name: string;
  organisation_email?: string | null;
  organisation_address?: string | null;
  organisation_zip_code?: string | null;
  organisation_city?: string | null;
  organisation_country?: string | null;
  organisation_vat_number?: string | null;
  organisation_registration_number?: string | null;
  shift_vat: boolean;
}

export interface EditParticipantAttributesInput {
  id: string;
  participant_attributes: ParticipantAttributes[];
}

export interface EditParticipantFieldInput {
  title: string;
  title_translations?: TranslationInput[] | null;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  required: boolean;
  unique?: boolean | null;
  config?: string | null;
  validation_url?: string | null;
  validation_success_response?: string | null;
  choices?: SaveParticipantFieldChoiceInput[] | null;
  tickets?: ModelType[] | null;
  products?: ModelType[] | null;
  promotions?: ModelType[] | null;
  enabled?: boolean | null;
  id: string;
}

export interface EditParticipantInput {
  id: string;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mail_opt_ins?: string[] | null;
  charity?: ModelType | null;
  unset_charity?: boolean | null;
  locale?: Locale | null;
}

export interface EditParticipantLocaleInput {
  id: string;
  locale?: Locale | null;
}

export interface EditPrivacyInput {
  project: ModelType;
  privacy_statement?: string | null;
  privacy_statement_translations?: TranslationInput[] | null;
}

export interface EditProductInput {
  info?: SaveProductInfoInput | null;
  pricing?: SaveProductPricingInput | null;
  restrictions?: SaveProductRestrictionsInput | null;
  product_variants?: SaveProductVariantInput[] | null;
  active?: boolean | null;
  id: string;
}

export interface EditPurchaseInput {
  id: string;
  ticket?: ModelType | null;
  product?: ModelType | null;
  promotion?: ModelType | null;
  time_slot?: ModelType | null;
  product_variant?: ModelType | null;
  revenue?: number | null;
  amount?: number | null;
  refund?: boolean | null;
  coupon?: string | null;
  delete_coupon?: boolean | null;
  invitation_code?: string | null;
  delete_invitation_code?: boolean | null;
  active?: boolean | null;
  ticket_number?: SetTicketNumberInput | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface EditRegistrationInput {
  id: string;
  claim_token?: string | null;
  participant?: ParticipantInput | null;
  unassign?: boolean | null;
  details?: RegistrationDetailsInput | null;
  team?: TeamInput | null;
  unset_team?: boolean | null;
  batch_update_team?: boolean | null;
  time_slot?: ModelType | null;
  upgrades?: EditUpgradeInput[] | null;
  charity?: ModelType | null;
  unset_charity?: boolean | null;
  send_notifications?: boolean | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface EditResaleSettingsInput {
  id: string;
  amount?: number | null;
  public?: boolean | null;
}

export interface EditScannerInput {
  id: string;
  info?: SaveScannerInfoInput | null;
  filters?: AddSegmentInput | null;
}

export interface EditServiceFeeInput {
  project: ModelType;
  pass_on_fee: boolean;
}

export interface EditTeamInput {
  id: string;
  title?: string | null;
  captain?: ModelType | null;
}

export interface EditTicketCategoryInput {
  title: string;
  title_translations?: TranslationInput[] | null;
  capacity?: number | null;
  id: string;
}

export interface EditTicketInput {
  ticket_category?: ModelType | null;
  info?: SaveTicketInfoInput | null;
  pricing?: SaveTicketPricingInput | null;
  type?: SaveTicketTypeInput | null;
  teams?: SaveTicketTeamsInput | null;
  restrictions?: SaveTicketRestrictionsInput | null;
  time_slots?: SaveTimeSlotInput[] | null;
  id: string;
}

export interface EditTicketNumberSequenceInput {
  id: string;
  info?: SaveTicketNumberSequenceInfoInput | null;
  filters?: AddSegmentInput | null;
  manual_entry?: ManualTicketNumbersInput | null;
}

export interface EditUpgradeInput {
  id: string;
  product_variant?: ModelType | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface EditUserInput {
  id: string;
  first_name: string;
  last_name: string;
}

export interface EditUserRoleInput {
  project_id: string;
  user_id: string;
  role: string;
}

export interface ExportColumnInput {
  column: string;
  title?: string | null;
}

export interface ExportOptions {
  format?: string | null;
  mailchimp?: MailchimpExportOptions | null;
}

export interface ExportOrdersInput {
  project: string;
  export_profile?: FindOrAddExportProfileInput | null;
  columns?: ExportColumnInput[] | null;
  filters?: OrderFilters | null;
  options?: ExportOptions | null;
}

export interface ExportPurchasesInput {
  project: string;
  export_profile?: FindOrAddExportProfileInput | null;
  columns?: ExportColumnInput[] | null;
  filters?: ParticipantFilters | null;
  options?: ExportOptions | null;
}

export interface ExportRegistrationsInput {
  project: string;
  export_profile?: FindOrAddExportProfileInput | null;
  columns?: ExportColumnInput[] | null;
  filters?: ParticipantFilters | null;
  options?: ExportOptions | null;
}

export interface ExternalValidationInput {
  participant_field: ModelType;
  value?: string | null;
  choices?: ModelType[] | null;
  registration: RegistrationInput;
  locale?: Locale | null;
}

export interface FinalizeParticipantFieldImportInput {
  import: ModelType;
  overwrite?: boolean | null;
}

export interface FindOrAddExportProfileInput {
  id?: string | null;
  title?: string | null;
}

export interface FindOrAddTeamInput {
  event?: ModelType | null;
  title?: string | null;
  captain?: ParticipantInput | null;
  id?: string | null;
}

export interface GenerateCouponCodesInput {
  coupon: ModelType;
  count: number;
}

export interface GoogleLocationInput {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  address?: string | null;
  coordinates: number[];
}

export interface InviteParticipantInput {
  id: string;
  email?: string | null;
}

export interface InvoiceDetailsInput {
  company_name: string;
  is_charity?: boolean | null;
  reference?: string | null;
  vat_id?: string | null;
  street: string;
  house_number: string;
  zip_code: string;
  extra_address_line?: string | null;
  city: string;
  country: string;
  phone?: string | null;
}

export interface MailchimpExportOptions {
  audience_id: string;
  tag: string;
  batch_id?: string | null;
}

export interface ManualTicketNumbersInput {
  ticket_numbers?: string | null;
}

export interface ModelType {
  id: string;
}

export interface OrderFilters {
  search?: string | null;
  events?: string[] | null;
  tickets?: string[] | null;
  time_slots?: string[] | null;
  products?: string[] | null;
  product_variants?: string[] | null;
  promotions?: string[] | null;
  invitations?: string[] | null;
  discounts?: string[] | null;
  coupons?: string[] | null;
  participant_fields?: ParticipantFieldFilter[] | null;
  participant_field_choices?: string[] | null;
  payment_statuses?: PurchasePaymentStatus[] | null;
  updated_after?: string | null;
}

export interface OrderSummaryInput {
  order: ModelType;
  token?: string | null;
}

export interface ParticipantFieldEntryInput {
  id?: string | null;
  participant_field: ModelType;
  value?: string | null;
  choices?: ModelType[] | null;
  order?: ModelType | null;
  purchase?: ModelType | null;
}

export interface ParticipantFieldFilter {
  id: string;
  filters?: ParticipantFieldFiltersInput | null;
}

export interface ParticipantFieldFiltersInput {
  min?: string | null;
  max?: string | null;
}

export interface ParticipantFilters {
  search?: string | null;
  events?: string[] | null;
  tickets?: string[] | null;
  time_slots?: string[] | null;
  ticket_number_sequences?: string[] | null;
  has_ticket_number?: boolean | null;
  products?: string[] | null;
  product_variants?: string[] | null;
  promotions?: string[] | null;
  participants?: string[] | null;
  not_participants?: string[] | null;
  participant_fields?: ParticipantFieldFilter[] | null;
  participant_field_choices?: string[] | null;
  invitations?: string[] | null;
  discounts?: string[] | null;
  coupons?: string[] | null;
  type?: PurchaseFilterType | null;
  statuses?: PurchaseStatus[] | null;
  payment_statuses?: PurchasePaymentStatus[] | null;
  active?: boolean | null;
  updated_after?: string | null;
  mail_opt_ins?: string[] | null;
  received_messages?: string[] | null;
  will_receive_messages?: string[] | null;
  genders?: Gender[] | null;
  min_age?: number | null;
  max_age?: number | null;
  has_team?: boolean | null;
}

export interface ParticipantInput {
  id?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  mail_opt_ins?: string[] | null;
  charity?: ModelType | null;
  unset_charity?: boolean | null;
  locale?: Locale | null;
}

export interface PaymentMethodInput {
  payment_method: string;
  issuer?: string | null;
}

export interface RefundInput {
  payment: ModelType;
  purchases: RefundPurchaseInput[];
  cancel?: boolean | null;
}

export interface RefundPurchaseInput {
  purchase: ModelType;
  amount: number;
}

export interface RegistrationDetailsInput {
  date_of_birth?: string | null;
  gender?: Gender | null;
  nationality?: string | null;
  street?: string | null;
  house_number?: string | null;
  zip_code?: string | null;
  city?: string | null;
  extra_address_line?: string | null;
  country?: string | null;
  phone?: string | null;
  emergency_phone?: string | null;
}

export interface RegistrationFilters {
  search?: string | null;
  events?: string[] | null;
  tickets?: string[] | null;
  time_slots?: string[] | null;
  ticket_number_sequences?: string[] | null;
  has_ticket_number?: boolean | null;
  teams?: string[] | null;
  has_team?: boolean | null;
  products?: string[] | null;
  product_variants?: string[] | null;
  promotions?: string[] | null;
  participants?: string[] | null;
  not_participants?: string[] | null;
  participant_fields?: ParticipantFieldFilter[] | null;
  participant_field_choices?: string[] | null;
  invitations?: string[] | null;
  coupons?: string[] | null;
  genders?: Gender[] | null;
  min_age?: number | null;
  max_age?: number | null;
  statuses?: PurchaseStatus[] | null;
  payment_statuses?: PurchasePaymentStatus[] | null;
  assigned?: boolean | null;
  active?: boolean | null;
  updated_after?: string | null;
}

export interface RegistrationInput {
  ticket?: ModelType | null;
  time_slot?: ModelType | null;
  purchase?: SavePurchaseInput | null;
  upgrades?: UpgradeInput[] | null;
  team?: TeamInput | null;
  participant?: ParticipantInput | null;
  details?: RegistrationDetailsInput | null;
  charity?: ModelType | null;
  fields?: ParticipantFieldEntryInput[] | null;
  id?: string | null;
}

export interface RegistrationsInput {
  create?: CreateRegistrationInput[] | null;
  update?: UpdateRegistrationInput[] | null;
  business?: BusinessRegistrationInput[] | null;
  stand_alone_upgrades?: CreateUpgradeInput[] | null;
}

export interface RequestDesignQuoteInput {
  name: string;
  email: string;
  extra?: string | null;
  organization: string;
  type: DesignQuoteType;
}

export interface RequestParticipantLoginInput {
  project: ModelType;
  event?: ModelType | null;
  email: string;
}

export interface RequestQuoteInput {
  website?: RequestWebsiteQuoteInput | null;
  ticket_scanner?: RequestTicketScannerQuoteInput | null;
  design?: RequestDesignQuoteInput | null;
}

export interface RequestTicketScannerQuoteInput {
  name: string;
  email: string;
  extra?: string | null;
  organization: string;
  start_date: string;
  nr_scanners: number;
  nr_days: number;
}

export interface RequestWebsiteQuoteInput {
  name: string;
  email: string;
  extra?: string | null;
  organization: string;
  custom: boolean;
}

export interface ResellRegistrationInput {
  id: string;
  resale_token: string;
  participant: ParticipantInput;
  details: RegistrationDetailsInput;
  upgrades: EditUpgradeInput[];
  charity?: ModelType | null;
  payment_method?: PaymentMethodInput | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface RevokeTicketNumbersInput {
  event: ModelType;
  ticket_number_sequence?: ModelType | null;
}

export interface SaveEventBrandingInput {
  logo?: any | null;
  delete_logo?: boolean | null;
  brand_color?: string | null;
}

export interface SaveEventConfirmationInput {
  confirmation_text?: string | null;
  confirmation_text_translations?: TranslationInput[] | null;
  confirmation_mail_text?: string | null;
  confirmation_mail_text_translations?: TranslationInput[] | null;
  notification_emails?: string[] | null;
}

export interface SaveEventDashboardSettingsInput {
  registrations_locked_at?: string | null;
  reassignable_tickets?: string[] | null;
  exchangeable_tickets?: string[] | null;
}

export interface SaveEventFundraisingSettingsInput {
  enable_fundraising: boolean;
  require_fundraising: boolean;
  fundraising_mode: FundraisingMode;
  allow_other_charity: boolean;
  charities: string[];
}

export interface SaveEventInfoInput {
  title: string;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  email?: string | null;
  category?: EventCategory | null;
  website_url?: string | null;
  location?: GoogleLocationInput | null;
  start_date: string;
  end_date: string;
}

export interface SaveEventLocaleInput {
  locale: Locale;
}

export interface SaveEventPaymentSettingsInput {
  payment_profile?: ModelType | null;
  require_company_for_bank_transfer?: boolean | null;
  invoice_prefix?: string | null;
  allow_down_payments: boolean;
  down_payment_percentage?: number | null;
  down_payment_deadline?: string | null;
  down_payment_tickets?: string[] | null;
  down_payment_products?: string[] | null;
}

export interface SaveEventResaleSettingsInput {
  enable_private_resale: boolean;
  enable_public_resale: boolean;
  resellable_tickets?: string[] | null;
}

export interface SaveEventRestrictionsInput {
  min_team_size?: number | null;
  max_team_size?: number | null;
  age_threshold_date?: string | null;
}

export interface SaveEventTermsInput {
  terms?: any | null;
  terms_url?: string | null;
  terms_text?: string | null;
  terms_text_translations?: TranslationInput[] | null;
}

export interface SaveEventTicketSettingsInput {
  enable_tickets: boolean;
  ticket_image?: any | null;
  delete_ticket_image?: boolean | null;
  ticket_attributes: TicketAttribute[];
  ticket_fields: string[];
  tickets_available_from?: string | null;
}

export interface SaveParticipantFieldChoiceInput {
  id?: string | null;
  title: string;
  title_translations?: TranslationInput[] | null;
  enabled: boolean;
}

export interface SaveProductInfoInput {
  title: string;
  title_translations?: TranslationInput[] | null;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  instructions?: string | null;
  instructions_translations?: TranslationInput[] | null;
  image?: any | null;
  delete_image?: boolean | null;
}

export interface SaveProductPricingInput {
  vat_percentage?: number | null;
  hide_on_invoice?: boolean | null;
  ledger_account?: string | null;
  promotions: SavePromotionInput[];
}

export interface SaveProductRestrictionsInput {
  capacity?: number | null;
  stand_alone?: boolean | null;
  required?: boolean | null;
  max_per_ticket?: number | null;
  max_per_order?: number | null;
  tickets?: string[] | null;
  products?: string[] | null;
}

export interface SaveProductVariantInput {
  id?: string | null;
  title: string;
  title_translations?: TranslationInput[] | null;
  capacity?: number | null;
  active?: boolean | null;
}

export interface SavePromotionInput {
  id?: string | null;
  title?: string | null;
  title_translations?: TranslationInput[] | null;
  description?: string | null;
  amount?: number | null;
  start_date?: string | null;
  end_date?: string | null;
  minimum_quantity?: number | null;
  capacity?: number | null;
  active?: boolean | null;
}

export interface SavePurchaseInput {
  promotion: ModelType;
  amount?: number | null;
}

export interface SaveScannerInfoInput {
  title: string;
  edit_start_numbers?: boolean | null;
}

export interface SaveTicketInfoInput {
  title: string;
  title_translations?: TranslationInput[] | null;
  description?: string | null;
  description_translations?: TranslationInput[] | null;
  instructions?: string | null;
  instructions_translations?: TranslationInput[] | null;
  image?: any | null;
  delete_image?: boolean | null;
}

export interface SaveTicketNumberSequenceInfoInput {
  title: string;
  start_number?: number | null;
  prefix?: string | null;
  suffix?: string | null;
  start_time?: string | null;
  corral_name?: string | null;
  capacity?: number | null;
  locked?: boolean | null;
}

export interface SaveTicketPricingInput {
  promotions: SavePromotionInput[];
  vat_percentage?: number | null;
  ledger_account?: string | null;
}

export interface SaveTicketRestrictionsInput {
  capacity?: number | null;
  max_per_order?: number | null;
  restrictions?: TicketRestrictionInput[] | null;
  assignment_type: AssignmentType;
}

export interface SaveTicketTeamsInput {
  allow_individuals: boolean;
  allow_create_team: boolean;
  allow_join_team: boolean;
}

export interface SaveTicketTypeInput {
  business: boolean;
  units: number;
}

export interface SaveTimeSlotInput {
  id?: string | null;
  start_date: string;
  start_time?: string | null;
  title?: string | null;
  title_translations?: TranslationInput[] | null;
  capacity?: number | null;
  active?: boolean | null;
}

export interface SendMessageInput {
  id: string;
  participant?: ModelType | null;
}

export interface SetTicketNumberInput {
  ticket_number_sequence?: ModelType | null;
  ticket_number?: number | null;
}

export interface SortParticipantFieldsInput {
  fields: ModelType[];
}

export interface SortProductsInput {
  products: ModelType[];
}

export interface SortTicketCategoryInput {
  id: string;
  tickets: string[];
}

export interface SortTicketsInput {
  ticket_categories: SortTicketCategoryInput[];
}

export interface TeamInput {
  id?: string | null;
  title?: string | null;
}

export interface TicketRestrictionInput {
  gender?: Gender | null;
  min_age?: number | null;
  max_age?: number | null;
}

export interface ToggleMailPermissionInput {
  id: string;
  events: string[];
  enable: boolean;
}

export interface ToggleMessageInput {
  id: string;
  publish: boolean;
}

export interface ToggleMolliePaymentMethodInput {
  mollie_profile: ModelType;
  payment_method_id: string;
}

export interface ToggleParticipantFieldInput {
  field: ModelType;
  enabled: boolean;
}

export interface ToggleScannerInput {
  id: string;
  enabled: boolean;
}

export interface ToggleTicketNumbersInput {
  id: string;
  publish: boolean;
}

export interface TranslationInput {
  locale: Locale;
  value?: string | null;
}

export interface UpdateRegistrationInput {
  id: string;
  upgrades: CreateUpgradeInput[];
}

export interface UpdateRegistrationsInput {
  participant: ModelType;
  event: ModelType;
  registrations: RegistrationsInput;
  invoice?: InvoiceDetailsInput | null;
  payment_method?: PaymentMethodInput | null;
  coupon?: string | null;
  fields?: ParticipantFieldEntryInput[] | null;
}

export interface UpgradeInput {
  product?: ModelType | null;
  product_variant?: ModelType | null;
  purchase?: SavePurchaseInput | null;
  fields?: ParticipantFieldEntryInput[] | null;
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
